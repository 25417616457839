import React from "react";
import myimage from "../images/my.jpg";

const Aboutme = () => {
  return (
    <div>
      <section id="about">
        <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-bold font- text-white ">
              <span className="text-orange-500">
                Hi, I'm Uddhav. <br />
              </span>
              <br className="hidden lg:inline-block " />I love Buliding Amazing
              Apps and Projects.
            </h1>
            <p className="mb-8 leading-relaxed ">
              Greetings to everybody! I'm a self-made frontend React developer
              named Uddhav Adhikari, and I'm 21 years old. My love for designing
              stunning and engaging user interfaces that improve the online
              experience led me to pursue a career in web
              development.
              <br />
              Creating smooth and interesting user experiences has always been
              my aim as a frontend developer. A well-thought-out user interface,
              in my opinion, may have a big influence on how people utilize
              digital platforms.
            </p>
            <div className="flex justify-center">
              <a
                href="#contact"
                className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-800 rounded text-lg"
              >
                Work With Me
              </a>
              <a
                href="#projects"
                className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg"
              >
                See My Past Work
              </a>
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded-3xl"
              alt="my"
              src={myimage}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Aboutme;
